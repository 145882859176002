<template>
  <div style="padding: 20px; background: #ffffff">
    <div style="padding: 20px 20px">
      <page_header title="助记词数据修改"></page_header>
    </div>
    <div class="lingg"></div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      style="margin: 0 auto"
    >
      <el-form-item label="代理ID" prop="name">
        <div class="flex_wrap">
          <div class="flex_item" style="width: 440px">
            <el-input
              v-model="ruleForm.px_id"
              placeholder="代理ID（可空：空为总后台）"
              :disabled="ruleForm.admin_px_id !== 1 ? true : false"
            ></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="助记词" prop="name">
        <div class="flex_wrap">
          <div class="flex_item" style="width: 440px">
            <el-input
              v-model="ruleForm.mnemonic"
              placeholder="助记词"
              :disabled="ruleForm.admin_px_id !== 1 ? true : false"
            ></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="BTC地址" prop="name">
        <div class="flex_wrap">
          <div class="flex_item" style="width: 440px">
            <el-input v-model="ruleForm.btc_address" placeholder="BTC地址（可空）" :disabled="ruleForm.admin_px_id !== 1 ? true : false"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="ETH地址" prop="name">
        <div class="flex_wrap">
          <div class="flex_item" style="width: 440px">
            <el-input v-model="ruleForm.eth_address" placeholder="ETH地址（可空）" :disabled="ruleForm.admin_px_id !== 1 ? true : false"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="TRX地址" prop="name">
        <div class="flex_wrap">
          <div class="flex_item" style="width: 440px">
            <el-input v-model="ruleForm.trx_address" placeholder="TRX地址（可空）" :disabled="ruleForm.admin_px_id !== 1 ? true : false"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="操作系统" prop="name">
        <div class="flex_wrap">
        <el-select v-model="ruleForm.system" placeholder="选择操作系统" :disabled="ruleForm.admin_px_id !== 1 ? true : false">
          <el-option label="android" value="android"> </el-option>
          <el-option label="ios" value="ios"> </el-option>
        </el-select>
        </div>
      </el-form-item>
      <el-form-item label="备注" prop="name">
        <div class="flex_wrap">
          <div class="flex_item" style="width: 440px">
            <el-input v-model="ruleForm.remark" placeholder="备注（可空）"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="flex_wrap">
          <div class="flex_item">
            <el-button type="primary" @click="submitForm('ruleForm')"
              >立即保存</el-button
            >
          </div>
          <div class="flex_item">
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<style>
.flex_wrap {
  display: flex;
}

.flex_item {
  margin-right: 20px;
}
</style>
<script>
// 上传
// import upload from "@/components/public/upload.vue";
// 编辑器
// import editor from "@/components/public/editor.vue";
// 页头
import page_header from "@/components/public/page_header.vue";
export default {
  components: {
    // upload,
    // editor,
    page_header,
  },
  data() {
    return {
      // 表单
      ruleForm: {
      },

      upload3: {
        headers: {
          token: localStorage.token,
        },
        upload_url: this.BaseUrl + "/admin/upload",
        // list_type: "picture-card",
        limit: 1,
        // file_type: ["png", "jpg", "jpeg"],
        file_sizi: 1,
        img_arr: [],
      },
      // 上传对象
      upload: {
        headers: {
          token: localStorage.token,
        },
        upload_url: this.BaseUrl + "/admin/image_upload",
        list_type: "picture-card",
        limit: 10,
        file_type: ["jpg", "png", "jpeg"],
        file_sizi: 200,
        img_arr: [],
      },

      // 验证器
      rules: {},
    };
  },
  methods: {
    types(file) {
      if (file.size > 15 * 1024 * 1024) {
        this.$back(0, "文件太大了 不能超过 " + 15 + "M", "warning");

        return false; //必须返回false
      }
    },
    handleChange(file, fileList) {
      this.upload3.img_arr = fileList.slice(-3);
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post(
              "/admin/tp_update",
              this.$qs.stringify(this.ruleForm)
            )
            .then((res) => {
              this.$back(res.data.code, res.data.msg);
              if (res.data.code == 200) {
                this.$redirect(1000, "/tp_mnemonic");
              }
            })
            .catch((error) => {
              this.$back(0, error);
            });
        } else {
          this.$back(0, "error submit!!");
          window.console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {
    // this.load_img(['/uploads/20191227/aa3b54bd0e35a2ec39197fe8ca124f01.jpg', '/uploads/20191227/aa3b54bd0e35a2ec39197fe8ca124f01.jpg'])
    window.console.log(this.upload.img_arr);

    this.$http
      .get("/admin/tp_edit", { params: { id: this.$route.query.id } })
      .then((res) => {
        if (res.data.code == 200) {
          this.ruleForm = res.data.data;
        }
      })
      .catch((error) => {
        this.$back(0, error);
      });
  },
};
</script>